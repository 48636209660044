import { getPlanBySlug } from '@smarty-nx/contentful'
import { logErrorAndNotify } from '@smarty-nx/debug-utils'
import { colors } from '@smarty-nx/ds'
import _ from 'lodash'

import { PlanData, PlanMeta } from './plans.types'

export const checkDataAmountIsDiff = (live, local) => {
  if (local.data_number === Infinity) {
    return [false, local.data_number]
  }

  if (!live.voucher) {
    return [false, local.data_number]
  }

  if (typeof live.voucher.data_amount !== 'number') {
    return [false, local.data_number]
  }

  if (local.data_number !== live.voucher.data_amount) {
    return [true, live.voucher.data_amount]
  }

  return [false, live.voucher.data_amount]
}

export const matchPlanMetaToPriceData = (plans, planSlug) => {
  const match = plans && plans.find((p) => p.slug === planSlug)

  return match || null
}

const defaultFn = (value) => value

const fnForColour = (value) => {
  if (colors?.[value]) {
    return colors[value]
  }
  throw new Error(`Missing color '${value}' in theme.json colours!`)
}

export const checkField = ({ data, key, defaultValue, cb = defaultFn }) => {
  try {
    const value = _.get(data, key)
    if (value) {
      return cb(value)
    }
    throw new Error(`Missing key '${key}' for ${data.slug} in Contentful CMS!`)
  } catch (error: any) {
    logErrorAndNotify(error, { data: error.message })
    return defaultValue
  }
}

export const buildPlanMetaObject = (slug: string) => {
  const planData = getPlanBySlug(slug)

  if (!planData) {
    return null
  }

  const planMeta: PlanMeta = {
    id: planData.id,
    name: planData.name,
    slug: planData.slug,
    title: planData.title || '',
    description: planData.description,
    color: planData.planColor?.colourCode,
    colorLight: planData.planColorPale?.colourCode,
    data: planData.data || '',
    data_discount: planData.dataDiscount || null,
    data_number: 0,
    plan_is_data_only: false,
    usesYellowAssets: planData.usesYellowAssets,
  }

  return planMeta
}

export const matchPlanMeta = (
  plans,
  slug: string
): { planMeta: any; priceData: PlanData } => {
  const priceData = matchPlanMetaToPriceData(plans, slug)
  let planMeta: PlanMeta | null = buildPlanMetaObject(slug)

  if (priceData) {
    planMeta = {
      ...planMeta,
      name: checkField({
        data: priceData,
        key: 'name',
        defaultValue: planMeta?.name,
      }),
      data_number: priceData.dataAllowanceGB,
      description: checkField({
        data: priceData,
        key: 'description',
        defaultValue: planMeta?.description,
      }),
      color: checkField({
        data: priceData,
        key: 'style.planColor',
        defaultValue: planMeta?.color,
        cb: fnForColour,
      }),
      colorLight: checkField({
        data: priceData,
        key: 'style.planColorPale',
        defaultValue: planMeta?.colorLight,
        cb: fnForColour,
      }),
      plan_is_data_only: priceData.planIsDataOnly,
    } as PlanMeta
  }

  return { priceData, planMeta }
}

export const returnDataWithUnit = (dataValue: number) =>
  dataValue < 1 ? `${dataValue * 1000}MB` : `${dataValue}GB`
