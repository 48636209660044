import React from 'react'

import { breakpoints, colors } from '@smarty-nx/ds'
import { css } from 'styled-components'

import { planDetailsContent } from './content'
import Button from '../../components/Button'
import DiscountCard from '../../components/DiscountCard'
import {
  HowItWorksSteps,
  HowItWorksTimeline,
} from '../../components/HowItWorksTimeline'
import CenteredButton from '../../components/styles/CenteredButton'
import FullWidthContainer from '../../components/styles/FullWidthContainer'
import {
  HideOnMobile,
  HideTabletUpwards,
} from '../../components/styles/Responsive'
import SiteContainer from '../../components/styles/SiteContainer'
import Spacer from '../../components/styles/Spacer'
import { WhySmartyPlans } from '../../components/WhySmarty/WhySmarty'
import {
  CHECKOUT_TYPES,
  TRACKING_CATEGORIES,
  TRACKING_STRINGS,
} from '../../constants/globalStrings'
import AllPlans from '../../modular-system/AllPlans'
import CoverageBlock from '../../modular-system/CoverageBlock'
import LogoGarden from '../../modular-system/LogoGarden'
import Quote from '../../modular-system/Quote'
import UnlimitedBanner from '../../modular-system/UnlimitedBanner'

export const renderAllPlansInclude = (plan) => (
  <AllPlans currentPlan={plan} hasIcon />
)

export const renderCoverage = () => (
  <FullWidthContainer
    background={colors['UI/DarkGrey']}
    data-testid="coverage-section"
  >
    <SiteContainer>
      <CoverageBlock />
    </SiteContainer>
  </FullWidthContainer>
)

export const renderDiscountCard = () => (
  <div>
    <Spacer size={0.5} />
    <FullWidthContainer
      background={colors['UI/DarkGrey']}
      css={css`
        padding: 4rem 0;
      `}
    >
      <SiteContainer>
        <DiscountCard />
      </SiteContainer>
    </FullWidthContainer>
    <Spacer size={0.5} />
  </div>
)

export const renderLogoGarden = () => (
  <FullWidthContainer background={colors['UI/DarkGrey']}>
    <SiteContainer>
      <LogoGarden
        css={css`
          margin-left: 0;
          margin-right: 0;
          :nth-child(n + 2) {
            display: none;
            @media (min-width: ${breakpoints['desktop-small']}px) {
              display: block;
            }
          }
        `}
      />
    </SiteContainer>
  </FullWidthContainer>
)

export const renderTrackedCTA = (plan, category, name) => (
  <CenteredButton>
    <Button
      href={`/order/${plan.slug}`}
      eventName={TRACKING_STRINGS.ADD_TO_CART}
      eventProductId={plan.slug}
      eventCheckoutType={CHECKOUT_TYPES.REGULAR}
      color={plan.color}
      wide
      data-item-category={category}
      data-item-name={name}
    >
      Order your {plan.name} SIM in minutes
    </Button>
  </CenteredButton>
)

export const renderQuote = () => (
  <SiteContainer
    css={css`
      margin-bottom: 80px;
    `}
  >
    <Quote
      text="Simple, fast and effective. Everything is exactly as advised: no surprises or hidden costs"
      source="Alfredo"
      css={css`
        padding: 48px 0 0;
      `}
    />
  </SiteContainer>
)

export const renderTimeline = (plan) => (
  <div data-testid="how-it-works-section">
    <HideTabletUpwards>
      <HowItWorksTimeline plan={plan} />
    </HideTabletUpwards>
    <HideOnMobile>
      <HowItWorksSteps plan={plan} />
    </HideOnMobile>
    {renderTrackedCTA(
      plan,
      TRACKING_CATEGORIES.NAVIGATION,
      planDetailsContent.ctaDataItemName
    )}
  </div>
)

export const renderUnlimitedBanner = () => (
  <div data-testid="unlimited-banner">
    <Spacer size={0.25} />
    <UnlimitedBanner />
    <Spacer size={0.25} />
  </div>
)

export const renderWhySmarty = () => (
  <div>
    <Spacer size={1.15} />
    <SiteContainer>
      <WhySmartyPlans darkTheme />
    </SiteContainer>
    <Spacer size={1.15} />
  </div>
)
