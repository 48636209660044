import React from 'react'

import { graphql, Link } from 'gatsby'

import classes from './index.module.scss'
import PageHead from '../../../components/PageHead'
import GlobeSVG from '../../../static/images/alt-globe.svg'
import { RoamingQueryData } from '../../../types/roaming.types'
import { alphabeticalOrder } from '../../../utils/sorting'

export default function International({ data }: RoamingQueryData) {
  return (
    <div className={classes.root}>
      <p className={classes.breadcrumbWrapper}>
        <Link to="/roaming">Roaming & international</Link>
        <span>{'>'}</span> International
      </p>
      <h1 className={classes.heading}>
        <span>Travelling outside the EU?</span>
        <span>
          <img src={GlobeSVG} alt="" />
        </span>
      </h1>
      <h3 className={classes.subHeading}>
        Buy “Out of plan” add-ons, then{' '}
        <Link to="/pay-as-you-go">pay as you go</Link>.
      </h3>
      <p className={classes.altParagraph}>
        To call and text abroad from the UK, buy “Out of plan” add-ons then{' '}
        <Link to="/pay-as-you-go">pay as you go</Link>. The cost depends on the
        country you want to contact.
      </p>
      <p className={classes.altParagraph}>
        Select the country you are travelling to to see the costs of using your
        phone while there.
      </p>
      <p className={classes.altParagraph}>
        Please check the{' '}
        <Link
          to="https://cdn.smarty.co.uk/files/SMARTY-Price-Guide.pdf"
          target="_blank"
        >
          Price Guide
        </Link>{' '}
        for the most up to date prices and countries and Fair Use Policy.
      </p>
      <div className={classes.container}>
        <ol className={classes.list}>
          {data.allRoamingPrice.edges.sort(alphabeticalOrder).map(
            ({ node }) =>
              node.country && (
                <li key={node.slug}>
                  <Link to={`/roaming/international/${node.slug}`}>
                    <img
                      className={classes.flag}
                      src={`/assets/images/flags/${node.iso_country_code.toLowerCase()}.png`}
                      alt=""
                    />
                    {node.display_country}
                  </Link>
                </li>
              )
          )}
        </ol>
        <div className={classes.card}>
          <h2 className={classes.cardHeading}>Before you go</h2>
          <h2>Phone settings update required</h2>
          <p>
            Your SMARTY SIM won’t work abroad unless you make a one-off change
            to your phone settings.
          </p>
          <Link to="/roaming-setup">Set up your phone now</Link>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allRoamingPrice(filter: { european_country: { eq: false } }) {
      edges {
        node {
          display_country
          country
          slug
          iso_country_code
        }
      }
    }
  }
`

export const Head = () => (
  <PageHead title="International calling price finder" />
)
