export const usageDefaults = {
  data: {
    title: 'Unlimited Data',
    value: 0,
    unit: 'GB',
  },
  calls: {
    title: 'Unlimited Calls',
    value: 0,
    unit: 'Mins',
  },
  voicecall: {
    title: 'Unlimited Calls',
    value: 0,
    unit: 'Mins',
  },
  sms: {
    title: 'Unlimited Texts',
    value: 0,
    unit: 'Texts',
  },
}
