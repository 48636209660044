import React, { FC } from 'react'

import { pricePresenterWithPence } from '@smarty-nx/code-utils'
import { Typography } from '@smarty-nx/ds'
import isNumber from 'lodash/isNumber'

import classes from './index.module.scss'
import { ShortcodeData } from '../../state/shortcode/types'
import Accordion from '../Accordion'

const EMPTY_SHORTCODE_FIELD = 'Not supplied'

interface ResultItemProps {
  title: string
  value?: string
  dataTestId: string
  emptyValuePlaceholder?: string
}

const ResultItem: FC<ResultItemProps> = ({
  title,
  value,
  dataTestId,
  emptyValuePlaceholder = EMPTY_SHORTCODE_FIELD,
}) => (
  <div className={classes.resultItem} data-testid={dataTestId}>
    <Typography
      component="span"
      variant="Kollektif/SmallEmphasis"
      data-testid={`${dataTestId}-label`}
    >
      {title}:
    </Typography>
    <Typography
      component="span"
      variant="Kollektif/Small"
      data-testid={`${dataTestId}-value`}
      className={classes.resultItemValue}
    >
      {value ?? emptyValuePlaceholder}
    </Typography>
  </div>
)

interface ShortcodeSearchResultsProps {
  shortcodeData: ShortcodeData | null
}

const ShortcodeSearchResults: FC<ShortcodeSearchResultsProps> = ({
  shortcodeData,
}) => {
  if (!shortcodeData) {
    return null
  }
  return (
    <div
      className={classes.shortcodeResults}
      data-testid="shortcode-search-results"
    >
      <div className={classes.resultTitle}>
        <Typography
          component="span"
          variant="Kollektif/MediumEmphasis"
          data-testid="results-merchant"
        >
          {shortcodeData.merchantName ?? EMPTY_SHORTCODE_FIELD}
        </Typography>
      </div>
      <div className={classes.resultRow}>
        <div className={classes.resultCol}>
          <ResultItem
            title="Shortcode"
            value={shortcodeData.shortcode}
            dataTestId={'results-shortcode'}
          />
          <ResultItem
            title="Service type"
            value={shortcodeData.serviceType}
            dataTestId={'results-service-type'}
          />
        </div>
        <div className={classes.resultCol}>
          <ResultItem
            title="Outgoing message cost"
            value={
              isNumber(shortcodeData?.outgoingMessageCost)
                ? pricePresenterWithPence(shortcodeData?.outgoingMessageCost)
                : undefined
            }
            dataTestId={'results-outgoing-cost'}
          />
          <ResultItem
            title="Incoming message cost"
            value={
              isNumber(shortcodeData?.incomingMessageCost)
                ? pricePresenterWithPence(shortcodeData?.incomingMessageCost)
                : undefined
            }
            dataTestId={'results-incoming-cost'}
          />
        </div>
      </div>
      <div className={classes.resultRow}>
        <div className={classes.separator} />
      </div>
      <div className={classes.resultTitle}>
        <Typography
          component="span"
          variant="Kollektif/MediumEmphasis"
          data-testid="results-contact-title"
        >
          Questions about this shortcode?
        </Typography>
        <Typography
          component="span"
          variant="Kollektif/Small"
          data-testid="results-contact-subtitle"
        >
          Here&apos;s the contact info you need:
        </Typography>
      </div>
      <div className={classes.resultRow}>
        <div className={classes.resultCol}>
          <ResultItem
            title="Email/Website"
            value={shortcodeData.customerCareContactEmail}
            dataTestId={'results-email'}
          />
        </div>
        <div className={classes.resultCol}>
          <ResultItem
            title="Phone number"
            value={shortcodeData.customerCareContactPhone}
            dataTestId={'results-phone-number'}
          />
        </div>
      </div>
      <div className={classes.resultRow}>
        <Accordion
          title="Payment intermediary details"
          isInitiallyOpened={false}
        >
          <div className={classes.resultRow}>
            <div className={classes.resultCol}>
              <ResultItem
                title="Email/Website"
                value={shortcodeData.customerCareEscalationEmail}
                dataTestId={'results-email-intermediary'}
              />
            </div>
            <div className={classes.resultCol}>
              <ResultItem
                title="Phone number"
                value={shortcodeData.customerCareEscalationPhone}
                dataTestId={'results-phone-number-intermediary'}
              />
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default ShortcodeSearchResults
