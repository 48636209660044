import React from 'react'

import { Helmet } from 'react-helmet'

import classes from './redirect.module.scss'
import LoadingDots from '../../components/LoadingDots'
import { SiteContainer } from '../../components/styles'
import { Centered } from '../../components/styles/Position'

interface RedirectProps {
  pageContext: {
    toPath: string
  }
}
const Redirect: React.FC<RedirectProps> = ({ pageContext }) => {
  const { toPath } = pageContext

  return (
    <span>
      <Helmet>
        <meta httpEquiv="refresh" content={`0; URL='${toPath}'`} />
        <link rel="canonical" href={toPath} />
      </Helmet>
      <SiteContainer>
        <Centered margin="20vh auto 20vh">
          <div className={classes.redirect}>
            <div>
              <LoadingDots />
            </div>
            <p>We&apos;re redirecting you now...</p>
            <p>
              Click <a href={toPath}>this link</a> if you are not redirected
              automatically
            </p>
          </div>
        </Centered>
      </SiteContainer>
    </span>
  )
}

export default Redirect
