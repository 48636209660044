import React, { useState, useEffect } from 'react'

import { Location } from 'history'
import { Prompt, useLocation, useHistory } from 'react-router-dom'

import { content } from './exit-modal.content'
import { Modal, ModalProps } from './modal'
import { Button } from '../button/button'

interface ExitModalProps extends Omit<ModalProps, 'isOpen'> {
  primaryCtaClickHandler: () => void
  allowedLinks: string[]
}

/* This modal prevents the user from navigating away from the current page to avoid data loss.
 * If the new route is non-react, native browser prompt will be displayed.
 */
export const ExitModal = (props: ExitModalProps) => {
  const { primaryCtaClickHandler, allowedLinks } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState<Location>(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = (nextLocation: Location) => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = (nextLocation: Location) => {
    if (!confirmedNavigation && !allowedLinks.includes(nextLocation.pathname)) {
      showModal(nextLocation)
      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal()
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setShouldUnload(true)
      history.push(lastLocation)
    }
  }, [confirmedNavigation, lastLocation, history])

  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (!shouldUnload) {
        event.returnValue = content.promptMessage
      }
      if (shouldUnload) {
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [shouldUnload])

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Modal
        {...props}
        isOpen={isModalOpen}
        showCloseButton={false}
        onRequestClose={closeModal}
        primaryCta={
          <Button
            onClick={() => {
              handleConfirmNavigationClick()
              primaryCtaClickHandler()
            }}
          >
            {content.continue}
          </Button>
        }
        secondaryCta={
          <Button level="secondary" onClick={() => closeModal()}>
            {content.cancel}
          </Button>
        }
      />
    </>
  )
}
