import { combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'

import { AppDispatch } from './createStore'
import dashboard from './dashboard/dashboard.slice'
import groupPlans from './groupPlans/group-plans.slice'
import plans from './plans/plans.slice'
import promotion from './promotion/promotion.slice'
import smartySharedData from './shared/shared.slice'
import shortcode from './shortcode/shortcode.slice'

export const rootReducer = combineReducers({
  plans,
  dashboard,
  smartySharedData,
  promotion,
  groupPlans,
  shortcode,
})

export type RootState = ReturnType<typeof rootReducer>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
