import React, { FC } from 'react'

import { useWindowSize } from '@smarty-nx/code-utils'
import { getFooter } from '@smarty-nx/contentful'
import { breakpoints } from '@smarty-nx/ds'
import cn from 'classnames'
import { Link } from 'gatsby'

import classes from './footer.module.scss'
import googlePlayStore from './google-play.svg'
import iosApp from './ios-app.svg'
import { CONFIG, FEATURES } from '../../config'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import { useIsAuthenticated } from '../../hooks'

const Footer: FC = () => {
  const isAuthenticated = useIsAuthenticated()
  const footerData = getFooter(
    'marketing',
    isAuthenticated ? 'Authenticated' : 'Unauthenticated'
  )
  const { width } = useWindowSize()
  const isMobileView = Boolean(width && width < breakpoints.tablet)
  return (
    <footer
      role="contentinfo"
      className={cn(classes.footerContainer, classes.redesignContainer)}
      data-testid="footer-container"
      data-module-name="footer"
    >
      <div className={classes.footerInner}>
        {footerData?.footerGroups?.map((footerGroup, i) => {
          return (
            <ul className={classes.footerColumn} key={i}>
              <li className={classes.footerItem}>
                <p className={classes.footerColumnTitle}>{footerGroup.title}</p>
              </li>
              {footerGroup.footerItems.map((footerItem, j) => {
                if (
                  !FEATURES.SHORT_CODES &&
                  footerItem.href === '/shortcodes-checker'
                ) {
                  return null
                }
                const attrs = {
                  target: footerItem.target,
                  rel: footerItem.rel,
                  'data-testid': footerItem.testId,
                  'data-item-category': TRACKING_CATEGORIES.NAVIGATION,
                  'data-item-name': footerItem.text,
                }
                return (
                  <li className={classes.footerItem} key={j}>
                    {footerItem.isRouterLink ? (
                      <Link to={footerItem.href} {...attrs}>
                        {footerItem.text}
                      </Link>
                    ) : (
                      <a href={footerItem.href} {...attrs}>
                        {footerItem.text}
                      </a>
                    )}
                  </li>
                )
              })}
            </ul>
          )
        })}
        {!isMobileView && (
          <ul className={cn(classes.footerColumn, classes.withActions)}>
            <li className={classes.footerItem}>
              {!isAuthenticated ? (
                <div className={classes.footerActions}>
                  <a
                    className={classes.footerButton}
                    href={`${
                      CONFIG.SMARTY_ENV === 'localhost'
                        ? CONFIG.DASHBOARD_URL
                        : '/'
                    }login`}
                    data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                    data-item-name="log-in"
                    data-testid="footer-login-button"
                  >
                    Log in
                  </a>
                  <span>Got a SIM?</span>
                  <a
                    href="/activate"
                    data-testid="activate-sim-button"
                    data-item-name="activate-sim"
                    data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                  >
                    Activate SIM
                  </a>
                  <button
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings cookie-pref-btn"
                  >
                    Cookie preferences
                  </button>
                </div>
              ) : (
                <div className={classes.footerActions}>
                  <a
                    className={classes.footerButton}
                    href={`${CONFIG.DASHBOARD_URL}`}
                    data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                    data-item-name="go-to-dashboard"
                    data-testid="footer-dashboard-button"
                  >
                    Dashboard
                  </a>
                  <span>
                    <a
                      href="/activate"
                      data-testid="activate-sim-button"
                      data-item-name="activate-sim"
                      data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                    >
                      Activate SIM
                    </a>
                  </span>
                  <button
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings cookie-pref-btn"
                  >
                    Cookie preferences
                  </button>
                </div>
              )}
            </li>
            <li className={classes.footerItem}>
              <a
                href="https://apps.apple.com/app/apple-store/id1540812668?pt=421745&mt=8&ct=smarty_web_download"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="iOS App"
                data-testid="footer-ios-app-download-link"
                data-item-name="ios-app-download-link"
                data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              >
                <img
                  className={classes.iOSAppImage}
                  alt="Ios App"
                  data-testid="ios-app"
                  src={iosApp}
                />
              </a>
            </li>
            <li className={classes.footerItem}>
              <a
                href="https://play.google.com/store/apps/details?id=com.hutchison3g.sundae&referrer=utm_source%3Dsmarty%26utm_medium%3Dweb%26utm_content%3Dappstore_logo"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Google play store App"
                data-testid="footer-android-app-download-link"
                data-item-name="android-app-download-link"
                data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              >
                <img
                  alt="Google Play Store App"
                  data-testid="google-play-store-app"
                  src={googlePlayStore}
                />
              </a>
            </li>
          </ul>
        )}
      </div>
      {isMobileView && (
        <div className={classes.footerBlockActions}>
          <div>
            {!isAuthenticated ? (
              <div className={classes.footerActions}>
                <a
                  className={classes.footerButton}
                  href={`${
                    CONFIG.SMARTY_ENV === 'localhost'
                      ? CONFIG.DASHBOARD_URL
                      : '/'
                  }login`}
                  data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                  data-item-name="log-in"
                  data-testid="footer-login-button"
                >
                  Log in
                </a>
                <span>Got a SIM?</span>
                <a
                  href="/activate"
                  data-testid="activate-sim-button"
                  data-item-name="activate-sim"
                  data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                >
                  Activate SIM
                </a>
                <button
                  id="ot-sdk-btn"
                  className="ot-sdk-show-settings cookie-pref-btn"
                >
                  Cookie preferences
                </button>
              </div>
            ) : (
              <div className={classes.footerActions}>
                <a
                  className={classes.footerButton}
                  href={`${CONFIG.DASHBOARD_URL}`}
                  data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                  data-item-name="go-to-dashboard"
                  data-testid="footer-dashboard-button"
                >
                  Go to my dashboard
                </a>
                <span>
                  <a
                    href="/activate"
                    data-testid="activate-sim-button"
                    data-item-name="activate-sim"
                    data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                  >
                    Activate SIM
                  </a>
                </span>
                <button
                  id="ot-sdk-btn"
                  className="ot-sdk-show-settings cookie-pref-btn"
                >
                  Cookie preferences
                </button>
              </div>
            )}
          </div>
          <div className={classes.apps}>
            <a
              href="https://apps.apple.com/app/apple-store/id1540812668?pt=421745&mt=8&ct=smarty_web_download"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="iOS App"
              data-testid="footer-ios-app-download-link"
              data-item-name="ios-app-download-link"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
            >
              <img
                className={classes.iOSAppImage}
                alt="Ios App"
                data-testid="ios-app"
                src={iosApp}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.hutchison3g.sundae&referrer=utm_source%3Dsmarty%26utm_medium%3Dweb%26utm_content%3Dappstore_logo"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Google play store App"
              data-testid="footer-android-app-download-link"
              data-item-name="android-app-download-link"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
            >
              <img
                alt="Google Play Store App"
                data-testid="google-play-store-app"
                src={googlePlayStore}
              />
            </a>
          </div>
        </div>
      )}
      <div className={classes.footerSocial}>
        <span data-testid="footer-social-span">Follow SMARTY</span>
        <a
          href="https://www.facebook.com/SMARTYMobileuk"
          rel="noopener noreferrer"
          target="_blank"
          id="facebook"
          data-testid="footer-facebook-icon"
        >
          Facebook
        </a>
        <a
          href="https://x.com/SMARTYMobileUK"
          rel="noopener noreferrer"
          target="_blank"
          id="x"
          data-testid="footer-x-icon"
        >
          X
        </a>
        <a
          href="https://www.youtube.com/@SMARTYMobileUK"
          rel="noopener noreferrer"
          target="_blank"
          id="youtube"
          data-testid="footer-youtube-icon"
        >
          YouTube
        </a>
        <a
          href="https://www.linkedin.com/company/smartymobile-uk/"
          rel="noopener noreferrer"
          target="_blank"
          id="linkedin"
          data-testid="footer-linkedin-icon"
        >
          LinkedIn
        </a>
        <a
          href="https://www.instagram.com/smartymobileuk/"
          rel="noopener noreferrer"
          target="_blank"
          id="instagram"
          data-testid="footer-instagram-icon"
        >
          Instagram
        </a>
      </div>
      <div className={classes.footerTerms}>
        <p data-testid="footer-copyright">
          © {new Date().getFullYear()} All rights reserved. SMARTY.
        </p>
        <p>
          <a href="/" data-testid="footer-self-link">
            smarty.co.uk
          </a>
        </p>
        <p data-testid="footer-personal-use">
          SMARTY is a service that’s just for consumers and is only intended for
          personal use.
        </p>
        <p data-testid="footer-trading">
          SMARTY is a trading name of Hutchison 3G UK Limited
        </p>
        <div>
          <p data-testid="footer-address">
            Registered office: Hutchison 3G UK Limited, 450 Longwater Avenue,
            Green Park, Reading, Berkshire, RG2 6GF
          </p>

          <p data-testid="footer-comp-num">
            Registered in England & Wales Number 03885486
          </p>
          <p data-testid="footer-vat">VAT Registration Number 760 7292 22</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
