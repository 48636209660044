import React from 'react'

import { graphql, Link } from 'gatsby'

import classes from './index.module.scss'
import PageHead from '../../../components/PageHead'
import { CONFIG } from '../../../config'
import GlobeSVG from '../../../static/images/alt-globe.svg'
import { RoamingQueryData } from '../../../types/roaming.types'
import { alphabeticalOrder } from '../../../utils/sorting'

export default function Countries({ data }: RoamingQueryData) {
  return (
    <div className={classes.root}>
      <p className={classes.breadcrumbWrapper}>
        <Link to="/calling-abroad">Roaming & international</Link>
        <span>{'>'}</span> Countries
      </p>
      <h1 className={classes.heading}>
        International calling price finder
        <img src={GlobeSVG} alt="" />
      </h1>
      <p className={classes.altParagraph}>
        Select the country you’d like to call or text.
      </p>
      <p className={classes.altParagraph}>
        Please check the{' '}
        <Link
          to="https://cdn.smarty.co.uk/files/SMARTY-Price-Guide.pdf"
          target="_blank"
        >
          Price Guide
        </Link>{' '}
        for the most up to date prices and countries and Fair Use Policy.
      </p>
      <div className={classes.container}>
        <ol className={classes.list}>
          {data.allRoamingPrice.edges.sort(alphabeticalOrder).map(
            ({ node }) =>
              node.international_calls !== null && (
                <li key={node.slug}>
                  <Link to={`/calling-abroad/countries/${node.slug}`}>
                    <img
                      className={classes.flag}
                      src={`/assets/images/flags/${node.iso_country_code.toLowerCase()}.png`}
                      alt=""
                    />
                    {node.display_country}
                  </Link>
                </li>
              )
          )}
        </ol>
        <div className={classes.card}>
          <h2 className={classes.cardHeading}>Before you text or call</h2>
          <h2>Buy “Out of plan” add-ons</h2>
          <p>
            Data, calls or texts outside the EU won’t work if you don’t have
            some “Out of plan” add-ons in your account. If you don’t use all
            your “Out of plan” add-ons, you can choose to put that money towards
            your next month or you can use it next time you need it.
          </p>
          <a href={`${CONFIG.DASHBOARD_URL}cash-addons`}>
            Buy “Out of plan” add-ons
          </a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allRoamingPrice {
      edges {
        node {
          display_country
          slug
          iso_country_code
          international_calls
        }
      }
    }
  }
`

export const Head = () => (
  <PageHead title="International calling price finder" />
)
