import { FC } from 'react'

import classes from './badge.module.scss'
import colorsJson from '../../colors.json'

const { colors } = colorsJson

interface BadgeProps {
  text: string
  textColor?: keyof typeof colors
  backgroundColor?: keyof typeof colors
}

export const Badge: FC<BadgeProps> = ({
  text,
  textColor = 'UI/DarkGrey',
  backgroundColor = 'Brand/Yellow',
}) => {
  return (
    <span
      className={classes.simpleTag}
      style={{
        backgroundColor: colors[backgroundColor],
        color: colors[textColor],
      }}
    >
      {text}
    </span>
  )
}
