import { FEATURES } from '../../config'

export const pathFinder = {
  // info pages
  '': {
    name: 'home',
    type: 'homepage',
  },
  '/all-plans': {
    name: 'all-plans',
    type: 'product-list',
  },
  // info pages
  '/about-us': {
    name: 'about-us',
    type: 'general-info',
  },
  '/contact': {
    name: 'contact',
    type: 'general-info',
  },
  // landing pages
  '/free-wifi-on-london-underground': {
    name: 'free-wifi-on-london-underground',
    type: 'landing-page',
  },
  '/5g': {
    name: '5g',
    type: 'landing-page',
  },
  '/data-rollover': {
    name: 'data-rollover',
    type: 'landing-page',
  },
  '/app': {
    name: 'app',
    type: 'landing-page',
  },
  '/data-sim': {
    name: 'data-sim',
    type: 'landing-page',
  },
  '/dashboard/groups/data-only-plans': {
    name: 'data-only-plans',
    type: 'landing-page',
  },
  '/friend': {
    name: 'friend',
    type: 'landing-page',
  },
  '/group-plans': {
    name: 'group-plans',
    type: 'landing-page',
  },
  '/less-malarkey': {
    name: 'less-malarkey',
    type: 'general-info',
  },
  '/money-back': {
    name: 'money-back',
    type: 'landing-page',
  },
  '/no-credit-check-sim': {
    name: 'no-credit-check-sim',
    type: 'landing-page',
  },
  '/pay-as-you-go': {
    name: 'pay-as-you-go',
    type: 'landing-page',
  },
  '/refer-a-friend': {
    name: FEATURES.REFER_AND_EARN ? 'refer-and-earn' : 'refer-a-friend',
    type: 'landing-page',
  },
  '/free-sim': {
    name: 'free-sim',
    type: 'landing-page',
  },
  '/sim-in-hand': {
    name: 'social-tariff-sim-check',
    type: 'general-info',
  },
  '/sim-only': {
    name: 'sim-only',
    type: 'landing-page',
  },
  '/student-sim': {
    name: 'student-sim',
    type: 'landing-page',
  },
  '/social-tariff': {
    name: 'social-tariff',
    type: 'landing-page',
  },
  '/refurbished-phones': {
    name: 'reboxed',
    type: 'landing-page',
  },

  // support pages
  '/calling-abroad': {
    name: 'calling-abroad',
    type: 'support-help',
  },
  '/calling-abroad/countries': {
    name: 'calling-abroad.countries',
    type: 'support-help',
  },
  '/coverage-checker': {
    name: 'coverage-checker',
    type: 'support-help',
  },
  '/network-status-checker': {
    name: 'network-status-checker',
    type: 'support-help',
  },
  '/roaming': {
    name: 'roaming',
    type: 'support-help',
  },
  '/roaming/europe': {
    name: 'roaming.europe',
    type: 'support-help',
  },
  '/roaming/international': {
    name: 'roaming.international',
    type: 'support-help',
  },
  '/roaming-setup': {
    name: 'roaming-setup',
    type: 'support-help',
  },
  '/shortcodes-checker': {
    name: 'shortcode-checker',
    type: 'account',
  },
}
