import React, { FC } from 'react'

import cn from 'classnames'

import classes from './banner.module.scss'

interface BannerProps {
  title: React.ReactNode
  imageUrl: string
  description: React.ReactNode
  dataTestId?: string
}

export const Banner: FC<BannerProps> = ({
  imageUrl,
  title,
  description,
  dataTestId = 'banner',
}) => {
  return (
    <div className={classes.root} data-testid={dataTestId}>
      <div className={classes.content}>
        <p className={classes.title} data-testid={`${dataTestId}-title`}>
          {title}
        </p>
        <div className={classes.text} data-testid={`${dataTestId}-description`}>
          {description}
        </div>
      </div>
      <div
        className={cn(classes.imageBlock)}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    </div>
  )
}
