import pages from '../../data/pages.json'

export const getPageByPath = (pathName: string) => {
  return pages.find(({ path }) => pathName === path)
}

export const getFamilyPlanInvitationPage = () =>
  getPageByPath('/groups/family-plan-invitation')

export const getInvitationErrorPage = () =>
  getPageByPath('/groups/invite-error')

export const getNumberSwitchInProgress = () =>
  getPageByPath('/dashboard/groups/number-switch-in-progress')

export const getClosingYourOldAccount = () =>
  getPageByPath('/dashboard/groups/closing-your-old-account')

export const getResumePlanInProgress = () =>
  getPageByPath('/dashboard/groups/resume-plan-scheduled')

export const getRestartingPlanInProgress = () =>
  getPageByPath('/dashboard/groups/restart-plan-confirmation')
