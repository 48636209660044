import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import {
  ErrorResponseType,
  FetchShortcodeDataParams,
  ResponseErrorItem,
  ShortcodeData,
  ShortcodeState,
  SuccessResponseType,
} from './types'
import api from '../../utils/api'

const initialState: ShortcodeState = {
  isFetching: false,
  data: null,
  error: null,
}

const SLICE_NAME = 'shortcode'

const LIFECYCLE_ERROR = 'Failed to fetch lifecycle for a shortcode'

export const SHORTCODE_MIN_LENGTH = 3
export const SHORTCODE_MAX_LENGTH = 6

export const ERROR_MESSAGE_VALIDATION = `Oops! Invalid shortcode, please enter a number between ${SHORTCODE_MIN_LENGTH} and ${SHORTCODE_MAX_LENGTH} digits.`
export const ERROR_MESSAGE_GENERIC =
  'Oops! We’ve hit a snag. Please try again soon.'
export const ERROR_MESSAGE_NOT_FOUND =
  'Sorry, this shortcode isn’t available on SMARTY.'

const getError = (
  statusCode: number | undefined,
  errorMessages: ResponseErrorItem[] | undefined
) => {
  const isLifecycleError =
    errorMessages &&
    errorMessages.find((item) => item?.detail === LIFECYCLE_ERROR)

  if (statusCode === 404) {
    return ERROR_MESSAGE_NOT_FOUND
  }

  if (statusCode === 422 && !isLifecycleError) {
    return ERROR_MESSAGE_VALIDATION
  }

  return ERROR_MESSAGE_GENERIC
}

export const fetchShortcodeData = createAsyncThunk<
  ShortcodeData,
  FetchShortcodeDataParams
>(SLICE_NAME, async ({ code }, { rejectWithValue }) => {
  try {
    const result = await api.get<ErrorResponseType, SuccessResponseType>(
      `/v1/shortcode?shortcode=${code}`
    )
    return result.data.data.attributes
  } catch (error) {
    const statusCode = axios.isAxiosError(error)
      ? error?.response?.status
      : undefined
    const errorMessages = axios.isAxiosError(error)
      ? error?.response?.data?.errors
      : undefined

    const errorMessage = getError(statusCode, errorMessages)

    return rejectWithValue(errorMessage)
  }
})

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShortcodeData.pending, (state) => {
        state.isFetching = true
        state.error = null
        state.data = null
      })
      .addCase(fetchShortcodeData.fulfilled, (state, action) => {
        state.isFetching = false
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchShortcodeData.rejected, (state, action) => {
        state.isFetching = false
        state.data = null
        state.error = action.payload
      })
  },
})

export const { reset: resetShortcodeData } = slice.actions
export default slice.reducer
