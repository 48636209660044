import React, { useCallback, useEffect } from 'react'

import { Banner, Button, TextInput, Typography } from '@smarty-nx/ds'
import cn from 'classnames'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import classes from './index.module.scss'
import LoadingDots from '../../components/LoadingDots'
import PageHead from '../../components/PageHead'
import ShortcodeSearchResults from '../../components/ShortcodeSearchResults'
import TextLink from '../../components/TextLink'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import { useAppDispatch, useAppSelector } from '../../state/rootReducer'
import {
  fetchShortcodeData,
  resetShortcodeData,
} from '../../state/shortcode/shortcode.slice'
import bannerImage from '../../static/images/shortcodes-banner.svg'
import { safeLocation } from '../../utils/safeWindow'

const SHORTCODE_FIELD_NAME = 'shortcode'

const ShortcodesChecker = () => {
  const queryString = safeLocation().search ?? ''
  const searchParams = new URLSearchParams(queryString)
  const code = searchParams.get(SHORTCODE_FIELD_NAME)
  const dispatch = useAppDispatch()
  const shortcodeRequest = useAppSelector((state) => state.shortcode)

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      shortcode: code || '',
    },
  })

  const submitApiRequest = useCallback(
    (shortcode: string) => {
      dispatch(fetchShortcodeData({ code: shortcode }))
    },
    [dispatch]
  )

  const shortCodeFormField = form.register(SHORTCODE_FIELD_NAME)

  useEffect(() => {
    if (shortcodeRequest.error) {
      form.setError(SHORTCODE_FIELD_NAME, {
        type: 'custom',
        message: shortcodeRequest.error,
      })
    }
  }, [form, shortcodeRequest.error])

  useEffect(() => {
    dispatch(resetShortcodeData())
  }, [dispatch])

  useEffect(() => {
    if (code && form.formState.submitCount === 0) {
      submitApiRequest(code)
    }
  }, [code, form.formState.submitCount, submitApiRequest])

  const onSubmit = (formData) => {
    const code = formData.shortcode

    searchParams.set(SHORTCODE_FIELD_NAME, code)
    submitApiRequest(code)
    navigate(`?${searchParams.toString()}`)
  }

  return (
    <div className={classes.root}>
      <div className={cn('redesign-container', classes.container)}>
        <Typography
          component="h1"
          variant="Kollektif/ExtraLarge"
          className={classes.title}
          data-testid="page-title"
        >
          Understanding Shortcode Charges
        </Typography>

        <Typography
          component="p"
          variant="Kollektif/Large"
          className={classes.description}
          data-testid="page-description"
        >
          A shortcode is a number (usually 5 digits long), used to access
          services not included in your plan. For example, you might text a
          shortcode to donate to charity or enter a competition.
        </Typography>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className={classes.shortcodeForm}
        >
          <div className={classes.field}>
            <TextInput
              {...shortCodeFormField}
              labelContent={
                <Typography
                  component="span"
                  variant="Kollektif/BodyTextLgBold"
                  data-testid="shortcode-label"
                >
                  Enter the shortcode number to find out charges
                </Typography>
              }
              dataTestId="shortcode-input"
              fieldDescription={
                !form.formState.errors[SHORTCODE_FIELD_NAME] &&
                'Usually 5 digits long'
              }
              onReset={() => {
                form.setValue(SHORTCODE_FIELD_NAME, '')
              }}
              type="text"
              error={form.formState.errors[SHORTCODE_FIELD_NAME]?.message ?? ''}
              data-testid="shortcode-input"
            />
          </div>
          <div className={classes.field}>
            <Button
              type="submit"
              disabled={shortcodeRequest.isFetching}
              data-testid="submit"
              data-item-category={TRACKING_CATEGORIES.GENERAL_INTERACTION}
              data-item-name="find-your-shortcode"
            >
              Find your shortcode
            </Button>
          </div>
        </form>
        {shortcodeRequest.isFetching && (
          <div className={classes.loadingWrapper} data-testid="preloader">
            <LoadingDots />
          </div>
        )}
        <ShortcodeSearchResults shortcodeData={shortcodeRequest.data} />
        <Banner
          title={'What are shortcodes?'}
          dataTestId="shortcode-info-banner"
          description={
            <div>
              Discover everything you need to know about shortcodes in our{' '}
              <TextLink
                href="https://help.smarty.co.uk/en/articles/10364848-a-quick-guide-to-shortcodes"
                target={'_blank'}
                data-item-category={TRACKING_CATEGORIES.NAVIGATION}
                data-item-name="help-article"
              >
                help article
              </TextLink>
              .
            </div>
          }
          imageUrl={bannerImage}
        />
      </div>
    </div>
  )
}

export const Head = () => (
  <PageHead
    title="Understanding Shortcode Charges"
    description="Understanding Shortcode Charges"
  />
)

export default ShortcodesChecker
