import React from 'react'

import { graphql, Link } from 'gatsby'

import classes from './index.module.scss'
import PageHead from '../../../components/PageHead'
import EuropeSVG from '../../../static/images/europe.svg'
import { RoamingQueryData } from '../../../types/roaming.types'
import { alphabeticalOrder } from '../../../utils/sorting'

export default function Europe({ data }: RoamingQueryData) {
  return (
    <div className={classes.root}>
      <p className={classes.breadcrumbWrapper}>
        <Link to="/roaming">Roaming &amp; international</Link>
        <span>{'>'}</span> Europe
      </p>
      <h1 className={classes.heading}>
        <span>Travelling in the EU?</span>
        <span>
          <img src={EuropeSVG} alt="" />
        </span>
      </h1>
      <h3 className={classes.subHeading}>
        Use your SMARTY plan anywhere in the EU
      </h3>
      <p className={classes.altParagraph}>
        Data comes out of your monthly allowance. Calls and Texts are unlimited.
        Please refer to our Fair Use Policy
      </p>
      <p className={classes.altParagraph}>
        Check if the country you’re travelling to is in the list:
      </p>
      <div className={classes.container}>
        <ol className={classes.list}>
          {data.allRoamingPrice.edges.sort(alphabeticalOrder).map(
            ({ node }) =>
              node.country && (
                <li key={node.slug}>
                  <Link to={`/roaming/europe/${node.slug}`}>
                    <img
                      className={classes.flag}
                      src={`/assets/images/flags/${node.iso_country_code.toLowerCase()}.png`}
                      alt=""
                    />
                    {node.display_country}
                  </Link>
                </li>
              )
          )}
        </ol>
        <div className={classes.card}>
          <h2 className={classes.cardHeading}>Before you go</h2>
          <h2>Phone settings update required</h2>
          <p className={classes.paragraph}>
            Your SMARTY SIM won’t work abroad unless you make a one-off change
            to your phone settings.
          </p>
          <a href="/roaming-setup">Set up your phone now</a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allRoamingPrice(filter: { european_country: { eq: true } }) {
      edges {
        node {
          display_country
          country
          slug
          iso_country_code
        }
      }
    }
  }
`

export const Head = () => <PageHead title="Travelling in the EU" />
