import React, { FC, ReactNode } from 'react'

import { Icon, Typography } from '@smarty-nx/ds'
import cn from 'classnames'

import classes from './index.module.scss'

interface AccordionProps {
  title: string
  isInitiallyOpened: boolean
  children?: ReactNode
  dataTestId?: string
  className?: string
}

const Accordion: FC<AccordionProps> = ({
  title,
  children,
  isInitiallyOpened,
  dataTestId = 'accordion',
}) => {
  const [isOpen, setIsOpen] = React.useState(isInitiallyOpened)
  return (
    <div data-testid={dataTestId}>
      <div
        className={classes.title}
        onClick={() => setIsOpen(!isOpen)}
        data-testid={`${dataTestId}-title`}
      >
        <Typography component="span" variant="Kollektif/ExtraSmallEmphasis">
          {title}
        </Typography>
        <Icon
          name="small-chevron-up"
          className={cn(classes.titleIcon, { [classes.closed]: !isOpen })}
          data-testid={`${dataTestId}-icon`}
        />
      </div>
      {isOpen && <div data-testid={`${dataTestId}-content`}>{children}</div>}
    </div>
  )
}

export default Accordion
