import React, { FC, useCallback, useEffect } from 'react'

import { priceToString } from '@smarty-nx/code-utils'
import { isUnlimitedPlan } from '@smarty-nx/contentful'
import braintree from 'braintree-web'

import ApplePayButton from './ApplePayButton'
import { planDetailsBannerContent } from './PlanDetailsBanner.content'
import {
  BannerBottom,
  BannerWrapper,
  Btn,
  ButtonWrapper,
  Chip,
  Description,
  DisplayData,
  OfferSubHeading,
  OfferWrapper,
  PlanDetails,
  StatementTitle,
  StrikedDataDesktop,
  StrikedDataMobile,
  StyledPaypalButton,
  Text,
} from './styles'
import { CONFIG, FEATURES } from '../../config'
import {
  CHECKOUT_TYPES,
  TRACKING_CATEGORIES,
  TRACKING_STRINGS,
} from '../../constants/globalStrings'
import { TC_LINK } from '../../global.constants'
import { useIsAuthenticated } from '../../hooks'
import PlanDetailLoading from '../../static/images/planDetailLoading.svg'
import PlanDetailLoadingMob from '../../static/images/planDetailLoadingMob.svg'
import api from '../../utils/api'
import { convertDataToDisplay, pricePresenter } from '../../utils/formatters'
import { renderPaypalButton } from '../../utils/paypal'
import { checkDataAmountIsDiff, returnDataWithUnit } from '../../utils/plans'
import { PlanData, PlanMeta } from '../../utils/plans.types'
import Button from '../Button'
import OfferRoundel from '../OfferRoundel'
import {
  HideOnMobile,
  HideOnTablet,
  HideSmallDesktopUpwards,
  HideTabletUpwards,
} from '../styles/Responsive'
import { Paragraph } from '../styles/Text'
import TextLink from '../TextLink'

interface PlanDetailsBannerProps {
  planMeta: PlanMeta
  priceData: PlanData
  buyBtnTitle?: string
  isPlanDetails?: boolean
}

export const PlanDetailsBanner: FC<PlanDetailsBannerProps> = ({
  isPlanDetails,
  planMeta,
  priceData,
  buyBtnTitle = 'Buy plan',
}) => {
  const isAuthenticated = useIsAuthenticated()

  const setupPaypal = useCallback(async () => {
    // call the api and get token
    try {
      const tokenResponse = await api('/v1/orders/token')
      if (tokenResponse?.data?.data?.attributes?.token) {
        const clientInstance = await braintree.client.create({
          authorization: tokenResponse.data.data.attributes.token,
        })
        let paypalCheckoutInstance = await braintree.paypalCheckout.create({
          client: clientInstance,
        })
        paypalCheckoutInstance = await paypalCheckoutInstance.loadPayPalSDK({
          vault: true,
          commit: false,
        })

        renderPaypalButton({
          buttonSelector: '#paypal-button',
          paypalCheckoutInstance,
          displayName: CONFIG.PAYPAL_DISPLAYNAME,
          billingAgreementDescription: `${
            priceData?.name
          } SIM plan for ${pricePresenter(price)} per month`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          locale: 'en_GB',
          plan: { planMeta, priceData },
          handleSuccessCallback: ({ payload }) => {
            const consolidatedData = {
              ...payload,
              ...data,
            }

            sessionStorage.setItem(
              'paypalData',
              JSON.stringify(consolidatedData)
            )
            const redirectUrl = `/order/${planMeta.slug}/express`
            window.location.replace(redirectUrl)
          },
          handleErrorCallback: (e) =>
            console.error('Error from paypal is ', { e }),
        })

        if (!isAuthenticated) {
          const googlePaymentInstance = await braintree.googlePayment.create({
            client: clientInstance,
            googlePayVersion: 2,
            googleMerchantId: 'merchant-id-from-google', // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
          })

          const paymentsClient = new window.google.payments.api.PaymentsClient({
            environment: CONFIG.GOOGLE_PAY_ENV,
          })

          paymentsClient
            .isReadyToPay({
              // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods:
                googlePaymentInstance.createPaymentDataRequest()
                  .allowedPaymentMethods,
              existingPaymentMethodRequired: true, // Optional
            })
            .then((response) => {
              if (response.result) {
                const handler = (event) => {
                  event.preventDefault()

                  const paymentDataRequest =
                    googlePaymentInstance.createPaymentDataRequest({
                      transactionInfo: {
                        currencyCode: 'GBP',
                        totalPriceStatus: 'FINAL',
                        totalPrice: event.target.getAttribute('data-price'),
                      },
                    })

                  // We recommend collecting billing address information, at minimum
                  // billing postal code, and passing that billing postal code with all
                  // Google Pay card transactions as a best practice.
                  // See all available options at https://developers.google.com/pay/api/web/reference/object
                  const cardPaymentMethod =
                    paymentDataRequest.allowedPaymentMethods[0]
                  cardPaymentMethod.parameters.billingAddressRequired = true
                  cardPaymentMethod.parameters.billingAddressParameters = {
                    format: 'FULL',
                    phoneNumberRequired: true,
                  }

                  paymentsClient
                    .loadPaymentData(paymentDataRequest)
                    .then((paymentData) =>
                      googlePaymentInstance.parseResponse(paymentData)
                    )
                    .then((result) => {
                      // Send result.nonce to your server
                      // result.type may be either "AndroidPayCard" or "PayPalAccount", and
                      // paymentData will contain the billingAddress for card payments
                      console.log(result)
                    })
                    .catch((err) => {
                      // Handle errors
                      console.error(err)
                    })
                }
                const button = document.getElementById('google-pay-button')
                if (button) {
                  button.onclick = handler
                }
              }
            })
            .catch((err) => {
              // Handle creation errors
              console.error(err)
            })
        }
      }
    } catch (e) {
      console.error('Error is ', { e })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, planMeta.slug, priceData?.name])

  useEffect(() => {
    if (FEATURES.PAYPAL_EXPRESS && !isAuthenticated) {
      setupPaypal()
    }
  }, [setupPaypal, isAuthenticated])

  if (!planMeta || !priceData) {
    return (
      <>
        <HideOnMobile>
          <img className="loading" src={PlanDetailLoading} alt="" />
        </HideOnMobile>
        <HideTabletUpwards>
          <img className="loading" src={PlanDetailLoadingMob} alt="" />
        </HideTabletUpwards>
      </>
    )
  }

  const isUnlimited = isUnlimitedPlan(planMeta.id)
  const isDataOnly = planMeta.plan_is_data_only
  const { voucher, groups, originalPrice, finalPriceWithoutCredit, tcChip } =
    priceData

  const defaultPrice = priceToString(originalPrice)
  const discountedPrice = priceToString(finalPriceWithoutCredit)
  const hasPriceChanged = originalPrice.value !== finalPriceWithoutCredit.value
  const price = hasPriceChanged ? discountedPrice : defaultPrice

  const isGroupDiscount = !!groups?.groupDiscountPercentage

  let planStatement
  if (planMeta.data_discount) {
    planStatement = isPlanDetails ? 'Money back for unused data' : 'Money back'
  } else {
    planStatement = 'No speed restrictions'
  }

  const defaultData = planMeta.data_number
  const [hasDataChanged, dataAmountSafe] = checkDataAmountIsDiff(
    priceData,
    planMeta
  )
  const data = priceData && convertDataToDisplay(defaultData, dataAmountSafe)

  const renderDataTypes = () => (
    <>
      5G & 4G data
      <Description>5G available with enabled devices</Description>
    </>
  )

  return (
    <BannerWrapper id={planMeta.name.toLowerCase()}>
      {isGroupDiscount && !voucher && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <OfferWrapper bgColor={planMeta.color}>
            <OfferSubHeading>10% off as a group member</OfferSubHeading>
            <Paragraph size="12px">
              <TextLink
                newTab
                href={TC_LINK}
                ariaLabel="Full offer T&amp;Cs .pdf opens in a new window"
              >
                Full offer T&amp;Cs
              </TextLink>
            </Paragraph>
          </OfferWrapper>
        </>
      )}
      {voucher && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <OfferWrapper bgColor={planMeta.color}>
            <OfferSubHeading>{voucher.plan_card_heading}</OfferSubHeading>
            <Paragraph size="12px">
              <TextLink
                newTab
                href={voucher.terms_and_conditions_url}
                ariaLabel="Full offer T&amp;Cs .pdf opens in a new window"
              >
                Full offer T&amp;Cs
              </TextLink>
            </Paragraph>
          </OfferWrapper>
          <HideSmallDesktopUpwards>
            <OfferRoundel
              voucher={voucher}
              roundelSide="right"
              roundelPosition="right"
              // data discount plans and 4gb-data plans use teal assets
              // big data & 500mb data use yellow assets
              usesYellowAssets={planMeta.usesYellowAssets}
            />
          </HideSmallDesktopUpwards>
          <HideOnTablet>
            <OfferRoundel
              voucher={voucher}
              roundelSide="left"
              roundelPosition="left"
              // data discount plans and 4gb-data plans use teal assets
              // big data & 500mb data use yellow assets
              usesYellowAssets={planMeta.usesYellowAssets}
            />
          </HideOnTablet>
        </>
      )}
      <PlanDetails
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bgColor={
          voucher || isGroupDiscount ? planMeta.colorLight : planMeta.color
        }
        borderTopRadius={!(voucher || isGroupDiscount)}
        data-testid="plan-details"
      >
        {tcChip && !voucher && (
          <Chip>
            <span>{tcChip}</span>
          </Chip>
        )}
        <div className="pricing">
          <h2 data-testid="plan-price">{priceData && price}</h2>
          {(voucher || isGroupDiscount) && hasPriceChanged ? (
            <p aria-label={`Usually priced at ${defaultPrice}`}>
              <span>{defaultPrice}</span> p/m
            </p>
          ) : (
            <p data-testid="capped-text">Capped per month</p>
          )}
        </div>
        <div
          className={`symbol equals${isDataOnly ? ' hide-tablet' : ''}`}
          data-testid="equals-sign"
        >
          =
        </div>
        <div className={`includes ${isDataOnly ? ' includes-data-only' : ''}`}>
          <h2 data-testid="calls-and-texts-limit">
            {isDataOnly ? 'Data Only' : 'Unlimited'}
          </h2>
          {!isDataOnly && (
            <p data-testid="calls-and-texts-text">
              {isUnlimited && (
                <span className="mobileCard">5G &amp; 4G Data, </span>
              )}
              UK calls &amp; texts
            </p>
          )}
        </div>
        {!isUnlimited ? (
          <>
            {!isDataOnly && (
              <div className="symbol add" data-testid="plus-sign">
                +
              </div>
            )}
            <div className="data" data-testid="data-amount">
              <DisplayData>
                {hasDataChanged && (
                  <StrikedDataDesktop>
                    {returnDataWithUnit(defaultData)}
                  </StrikedDataDesktop>
                )}
                {returnDataWithUnit(data)}
                {hasDataChanged && (
                  <StrikedDataMobile>
                    {returnDataWithUnit(defaultData)}
                  </StrikedDataMobile>
                )}
              </DisplayData>
              <Text data-testid="data-types">{renderDataTypes()}</Text>
            </div>
          </>
        ) : (
          <>
            {!isDataOnly && (
              <div className="symbol add hide-tablet-down">+</div>
            )}
            <div className={`data${!isDataOnly ? ' hide-tablet-down' : ''}`}>
              <h2>Unlimited</h2>
              <Text>{renderDataTypes()}</Text>
            </div>
          </>
        )}
      </PlanDetails>
      <BannerBottom>
        <div>
          <StatementTitle data-testid="plan-statement">
            {planStatement}
          </StatementTitle>
          <Paragraph data-testid="plan-statement-text">
            {planMeta.data_discount ? (
              isPlanDetails ? (
                <a
                  href="#expDiscount"
                  data-item-category="PP"
                  data-item-name="moneyback-more-info"
                >
                  {`Up to ${planMeta.data_discount} back every month`}
                </a>
              ) : (
                `Up to ${planMeta.data_discount} per month`
              )
            ) : (
              '1 month plan, cancel anytime'
            )}
          </Paragraph>
        </div>
        <ButtonWrapper>
          <Button
            ariaLabel={`Get the ${planMeta.name} plan`}
            eventName={TRACKING_STRINGS.ADD_TO_CART}
            eventProductId={planMeta.slug}
            eventCheckoutType={CHECKOUT_TYPES.REGULAR}
            href={`/order/${planMeta.slug}`}
            color={planMeta.color}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data-testid="get-plan-button"
            data-item-category={TRACKING_CATEGORIES.NAVIGATION}
            data-item-name={planDetailsBannerContent.ctaDataItemName}
            to={undefined}
            onClick={undefined}
            fullWidth={undefined}
            wide={undefined}
            linkStyle={undefined}
            centered={undefined}
            newTab={undefined}
            className={undefined}
            customStyle={undefined}
            custStyle={undefined}
          >
            {buyBtnTitle}
          </Button>
          {/** TODO: Remove before we release PayPal */}
          {FEATURES.PAYPAL_EXPRESS && !isAuthenticated && (
            <StyledPaypalButton
              id="paypal-button"
              data-item-category={TRACKING_CATEGORIES.NAVIGATION}
              data-item-name="pay-pal-buy-now"
            />
          )}
          {FEATURES.APPLE_PAY_PLAN_BANNER_BUTTON && !isAuthenticated && (
            <ApplePayButton
              label={`${priceData?.name} SIM plan for ${pricePresenter(
                price
              )} per month`}
              amount={Number(price.slice(1))}
            />
          )}
          {FEATURES.GOOGLE_PAY_PLAN_BANNER_BUTTON && !isAuthenticated && (
            <Btn id="google-pay-button" data-price={price.slice(1)}>
              Google Pay
            </Btn>
          )}
        </ButtonWrapper>
      </BannerBottom>
    </BannerWrapper>
  )
}
